import Form from "react-bootstrap/Form";

export type Props = {
    value: string,
    onChange: (value: string) => void
}

const EnvironmentTypeSelect = ({value, onChange}: Props) => {
    return (
        <Form.Select aria-label="Environment type select" value={value} onChange={(element) => { onChange(element.target.value); }} >
            <option value="development">Development</option>
            <option value="staging">Staging</option>
            <option value="production">Production</option>
        </Form.Select>
    );
}

export default EnvironmentTypeSelect;
