import {Server} from "../../types";
import {ReactSVG} from "react-svg";
import {Spinner} from "react-bootstrap";

const ServerIcon = ({server}: {server: Server}) => {
    const provisioned = (server.provisioned_at !== null)

    return (
        <div className="position-relative d-inline-block my-3">
            <ReactSVG src="/server2.svg" className={provisioned ? 'text-primary' : 'text-secondary'} />
            {!provisioned &&
                <div className="position-absolute bottom-0 end-0 top-0 start-0 bg-white bg-opacity-75 d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status" size="sm" className="text-primary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
        </div>
    );
}

export default ServerIcon;
