import Dropdown from 'react-bootstrap/Dropdown';

import {Backup, Server, Site} from "../../types";
import BackupDeleteAction from "./BackupDeleteAction";
import BackupCloneToAction from "./BackupCloneToAction";


export const BackupActions = ({backup, site, server}: {backup: Backup, site: Site, server: Server}) => {
    return (
        <>
            <Dropdown className="float-md-end">
                <Dropdown.Toggle id="dropdown-actions">
                    Actions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <BackupCloneToAction backup={backup} site={site} server={server}/>
                    <li>
                        <hr className="dropdown-divider"/>
                    </li>
                    <BackupDeleteAction backup={backup} site={site} server={server} />
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}
