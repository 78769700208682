import {Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";

import AdminPage from "./pages/admin/Admin";

const AdminRouter = () => {
    return (
        <Routes>
            <Route element={<Layout/>} path="/admin">
                <Route index element={<AdminPage/>}/>
            </Route>
        </Routes>
    );
}

export default AdminRouter;