import Dropdown from 'react-bootstrap/Dropdown';

import {Server} from "../../types";
import ServerEditAction from "./ServerEditAction";
import ServerAddUserAction from "./ServerAddUserAction";
import ServerRemoveUsersAction from "./ServerRemoveUsersAction";


export const ServerActions = ({server}: { server: Server }) => {
    return (
        <>
            <Dropdown className="float-md-end">
                <Dropdown.Toggle id="dropdown-actions">
                    Actions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <ServerAddUserAction server={server} />
                    <ServerRemoveUsersAction server={server} />
                    <ServerEditAction server={server} />
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}
