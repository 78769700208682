import {get} from "../../services/api";
import {Server} from "../../types";
import {useEffect, useState} from "react";
import ServerListItem from "../../components/admin/ServerListItem";

function AdminPage() {
    const [servers, setServers] = useState<Server[]>([])

    useEffect(() => {
        get<Server[]>(`/admin/servers`)
            .then((servers) => {
                setServers(servers);
            });
    }, []);

    return (
        <>
        <div>
            {
                servers.map((server) => (
                    <ServerListItem key={server.id} server={server} />
                ))
            }
        </div>
            <p>{ servers.length } Server(s)</p>
        </>
    )
}

export default AdminPage;
