import {useState, useEffect, createContext} from "react";
import {useLocation} from "react-router-dom";
import useWebSocket from 'react-use-websocket';

import {Server} from "./types";
import {ApiError} from "./services/api";

import './scss/style.scss';
import {WEBSOCKET_URL, get} from "./services/api";
import {CloseButton, Toast, ToastBody, ToastContainer} from "react-bootstrap";
import {BusyOverlay} from "./components/BusyOverlay";
import MainRouter from "./MainRouter";
import AdminRouter from "./AdminRouter";

export const AppContext = createContext<{
    setError: (e: ApiError|Error) => void
    setMessage: (message: string) => void
    setBusy: (state: boolean) => void
    setPending: (state: boolean) => void
}>({
    setError: (e) => e,
    setMessage: (message: string) => message,
    setBusy: (state: boolean) => state,
    setPending: (state: boolean) => state,
});
export const ServersContext = createContext<Server[]>([]);

function App() {
    const {hash, key} = useLocation()
    const [messages, setMessages] = useState<string[]>([])
    const [errors, setErrors] = useState<string[]>([])
    const [timestamp, setTimestamp] = useState(0);
    const [ready, setReady] = useState(false);
    const [busy, setBusy] = useState(false);
    const [pending, setPending] = useState(false);
    const [servers, setServers] = useState<Server[]>([]);

    const {lastMessage} = useWebSocket(WEBSOCKET_URL, {
        shouldReconnect: () => true,
        onError: (errorEvent) => console.log(errorEvent),
    });

    const setError = (e: ApiError|Error) => {
        if (e instanceof ApiError) {
            if (e.res.status === 401) {
                /* We're about to redirect, don't flash the error. */
                return;
            }
        }
        setErrors([...errors, e.message]);
    }
    const removeError = (index: number) => {
        if (errors.length > 1) {
            setErrors([...errors].splice(index, 1));
        } else {
            setErrors([]);
        }
    }
    const setMessage = (message: string) => {
        setMessages([...messages, message]);
        setTimeout(() => {
            setMessages(errors.filter(x => x === message));
        }, 3000);
    }

    useEffect(() => {
        if (lastMessage !== null) {
            setTimestamp(Date.now());
        }
    }, [lastMessage, setTimestamp]);

    useEffect(() => {
        get<Server[]>(`/servers`)
            .then((res) => {
                    setServers(res);
                    setReady(true);
                }
            )
            .catch((e) => {
                setError(e.message)
            })
            .finally(() => {
                setPending(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timestamp, setPending]);

    useEffect(()=>{
        if(hash){
            const targetElement = document.getElementById(hash.substring(1))
            targetElement?.scrollIntoView({behavior: 'smooth'})
        }
    }, [key, hash]);

    return (
        <AppContext.Provider value={{setError, setMessage, setBusy, setPending}}>
            {ready && <AdminRouter/>}
            <ServersContext.Provider value={servers}>
                <>
                    <ToastContainer position="top-center" containerPosition="fixed">
                        {
                            messages.map((message, index) => (
                                <Toast key={index} className="text-bg-success">
                                    <ToastBody className="text-center">
                                        {message}
                                    </ToastBody>
                                </Toast>
                            ))
                        }
                        {
                            errors.map((error, index) => (
                                <Toast key={index} className="text-bg-danger">
                                    <div className="d-flex">
                                        <ToastBody>
                                            {error}
                                        </ToastBody>
                                        <CloseButton onClick={() => removeError(index)}
                                                     className="me-2 m-auto"></CloseButton>
                                    </div>
                                </Toast>
                            ))
                        }
                    </ToastContainer>
                    {(!ready || busy || pending) && <BusyOverlay/>}
                    {ready && <MainRouter/>}
                </>
            </ServersContext.Provider>
        </AppContext.Provider>
    );
}

export default App;
