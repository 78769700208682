import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';

import {Backup, Server, Site} from "../../types";
import {ApiError, request} from "../../services/api";
import {AppContext} from "../../App";

export type Props = {
    backup: Backup,
    site: Site,
    server: Server
}

const BackupDeleteAction = ({backup, site, server}: Props) => {
    const [showModal, setShowModal] = useState(false);
    const app = useContext(AppContext);
    const navigate = useNavigate();

    function onDelete() {
        backup.status = "deleting";

        const requestOptions = {
            method: 'DELETE'
        };

        request(`/backups/${backup.id}`, requestOptions)
            .then(() => {
                app.setMessage('Backup successfully deleted.');
            })
            .catch((e) => {
                app.setError(e as ApiError);
            });

        setShowModal(false);
        navigate(`/servers/${server.id}/sites/${site.id}#backups`);
    }

    return (
        <>
            <Dropdown.Item as="button" onClick={() => setShowModal(true) }>
                <span className="text-danger">Delete</span>
            </Dropdown.Item>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete.</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to permanently delete this backup?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={onDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BackupDeleteAction;