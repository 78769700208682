import {useContext} from "react";
import {Link, useParams} from "react-router-dom";

import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

import {Site} from "../types";
import {ServersContext} from "../App";

import ServerNotFound from "../components/servers/ServerNotFound";

import {findServer, localDateTime, serverDisplayName} from "../utils";
import {ServerActions} from "../components/servers/ServerActions";
import {ServerSiteContainer} from "../components/servers/ServerSiteContainer";

const ServerPage = () => {

    let {serverId} = useParams();
    const servers = useContext(ServersContext);
    const server = findServer(serverId, servers);

    if (server === null) {
        return (<ServerNotFound id={serverId}/>);
    }

    const sites = {
        "production": [],
        "staging": [],
        "development": []
    } as Record<string, Site[]>;

    for (let site of server.sites) {
        sites[site.environment_type].push(site);
    }

    return (
        <>
            <div className="pb-4">
                <h1 className="mb-2 h2">Server Details</h1>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={'/'}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Server</li>
                </ol>
            </div>

            <div className="card bg-white shadow-sm mb-4 server-details">
                <div className="card-header d-flex align-items-center">
                    <h5 className="m-0 col">{serverDisplayName(server)}</h5>
                    <ServerActions server={server}/>
                </div>
                <div className="card-body p-4">
                    <div className="row g-2">
                        <div className="col-sm-3 col-xl-2 fw-semibold text-black">ID</div>
                        <div className="col-sm-9 col-xl-10">{server.id}</div>
                        <div className="col-sm-3 col-xl-2 fw-semibold text-black">Name</div>
                        <div className="col-sm-9 col-xl-10">{server.name}</div>
                        <div className="col-sm-3 col-xl-2 fw-semibold text-black">Hostname</div>
                        <div className="col-sm-9 col-xl-10">{server.hostname}</div>

                        <div className="col-sm-3 col-xl-2 fw-semibold text-black">IPv4</div>
                        <div className="col-sm-9 col-xl-10">{server.ipv4}</div>
                        {server.provisioned_at &&
                            <>
                                <div className="col-sm-3 col-xl-2 fw-semibold text-black">Provisioned At:</div>
                                <div className="col-sm-9 col-xl-10">{localDateTime(server.provisioned_at)}</div>
                            </>
                        }
                        {(server.auths.length > 0) &&
                            <>
                                <div className="col-sm-3 col-xl-2 fw-semibold text-black">Authorized User(s):</div>
                                <div className="col-sm-9 col-xl-10">
                                    {server.auths.map((auth) => (
                                        <p key={auth.id} className="mb-0">{auth.email}</p>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

            <DndProvider backend={HTML5Backend}>
                {
                    Object.keys(sites).map((key) => (
                        <ServerSiteContainer key={key} server={server} environmentType={key} sites={sites[key]}/>
                    ))
                }
            </DndProvider>
        </>
    );
}

export default ServerPage;
