import {useNavigate} from 'react-router-dom';
import {useDrag} from "react-dnd";

import {Site, Server} from "../../types";
import {siteDisplayName} from "../../utils";
import SiteIcon from "../sites/SiteIcon";

interface DropResult {
    name: string
}

type Props = {
    site: Site,
    server: Server,
    onDrop: (site: Site, environmentType: string) => void
}

const SiteLink = ({site, server, onDrop}: Props) => {
    const navigate = useNavigate();

    const [{opacity}, dragRef] = useDrag(
        () => ({
            type: site.environment_type,
            item: {site_id: site.id},
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult<DropResult>()
                if (item && dropResult) {
                    onDrop(site, dropResult.name)
                }
            },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1
            })
        }),
        []
    )

    function onClick() {
        navigate(`/servers/${server.id}/sites/${site.id}`)
    }

    function backupText(site: Site): string {
        if (site.backups.length === 1) {
            return '1 backup';
        }
        if (site.backups.length > 1) {
            return site.backups.length.toString() + ' backups';
        }
        return ''
    }

    return (
        <div ref={dragRef} style={{ opacity }} className="col-sm-4 text-center py-3" role="button" onClick={onClick}>
            <SiteIcon site={site}/>
            <p className="mb-1">{siteDisplayName(site)}</p>
            <p className="text-secondary small">{backupText(site)}</p>
        </div>
    )
};

export default SiteLink
