import Dropdown from "react-bootstrap/Dropdown";
import {Site, Server} from "../../types";
import {ApiError, post} from "../../services/api";
import {useContext} from "react";
import {AppContext} from "../../App";
import humanizeDuration from "humanize-duration";

type Props = {
    environmentType: string
    server: Server
    site: Site
}

const SiteCloneAction = ({environmentType, server, site}: Props) => {
    const app = useContext(AppContext);

    function clone() {
        app.setMessage("Site creation started.");
        const start = Date.now();
        post(`/sites`, {
            site_id: site.id,
            server_id: server.id,
            environment_type: environmentType
        })
            .then(() => {
                app.setMessage(`Site successfully created in ${humanizeDuration(Date.now() - start)}.`);
            })
            .catch((e) => {
                app.setError(e as ApiError);
            });
    }

    return (
        <Dropdown.Item as="button" onClick={clone}><span className="text-capitalize">{environmentType}</span></Dropdown.Item>
    );
}

export default SiteCloneAction;