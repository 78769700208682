import {useRouteError} from "react-router-dom";
import Header from "./Header";
import Container from "react-bootstrap/Container";

export default function ErrorPage() {
    const error = useRouteError() as Error;

    return (
        <>
            <Header/>
            <Container className={'d-flex justify-content-center py-5 vh-100 bg-white'} fluid>
                <div id="error-page">
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error.message}</i>
                    </p>
                </div>
            </Container>
        </>
    );
}