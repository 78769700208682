import {Backup} from "../../types";
import {statusClass, pending} from "../../utils";
import {ReactSVG} from "react-svg";
import {Spinner} from "react-bootstrap";

const BackupIcon = ({backup}: {backup: Backup}) => {
    const spinnerColor = backup.status === 'deleting' ? 'text-danger' : 'text-primary';

    return (
        <div className="position-relative d-inline-block my-3">
            <ReactSVG src="/backup.svg" className={statusClass(backup.status)} />
            {pending(backup.status) &&
                <div className="position-absolute bottom-0 end-0 top-0 start-0 bg-white bg-opacity-75 d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status" size="sm" className={spinnerColor}>
                        <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
            }
        </div>
    );
}

export default BackupIcon;