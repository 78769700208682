function SettingsPage() {
    return (
        <>
        <h1>Settings</h1>
        <p className="lead">Nothing to see here yet.</p>
        </>
    )
}

export default SettingsPage;
