import {Site} from "../../types";
import Dropdown from "react-bootstrap/Dropdown";
import {useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import {ApiError, post} from "../../services/api";
import {AppContext} from "../../App";

const SiteSslAction = ({site}: { site: Site }) => {
    const app = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const [fqdn, setFqdn] = useState(site.fqdn ? site.fqdn : '');

    function hostname() {
        app.setBusy(true);

        post(`/sites/${site.id}/hostname`, {
            hostname: fqdn
        })
            .then(() => {
                app.setMessage('Hostname successfully updated.');
            })
            .catch((e) => {
                app.setError(e as ApiError);
            })
            .finally(() => {
                app.setBusy(false);
            });

        setShowModal(false);
    }

    return (
        <>
            <Dropdown.Item disabled={site.status !== 'ok'} as="button" onClick={() => setShowModal(true)}>SSL Certificate</Dropdown.Item>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Generate a SSL Certificate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Hostname</Form.Label>
                            <Form.Control type="text" placeholder="Enter hostname" value={fqdn}
                                          onChange={(event) => setFqdn(event.target.value)}/>
                        </Form.Group>
                        <p><span className="text-warning">WARNING:&nbsp;</span>
                            Make sure you save the administrator username and password <strong>before</strong> changing the hostname.</p>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={hostname}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SiteSslAction;