import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser} from "@fortawesome/free-regular-svg-icons";


import Gravatar from "react-gravatar";
import {user} from '../../services/user';

const Avatar = () => {
    const me = user();

    if (!me || !me.user_email) {
        return <FontAwesomeIcon className="ms-2 me-auto" icon={faCircleUser}/>
    }

    return (
        <Gravatar style={{borderRadius: '50%'}} email={me.user_email} size={40} />
    )
}

export default Avatar;
