import {LinkContainer} from 'react-router-bootstrap'

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import ProfileDropdown from "../components/profile/ProfileDropdown";

function Header() {

    return (
        <header className={'bg-white shadow-sm sticky-top'}>
            <Container>
                <Navbar variant="light" expand="lg">
                    <LinkContainer to="/">
                        <Navbar.Brand>
                            <img src="/logo-dark.svg" alt="Mindspun Logo"/>
                        </Navbar.Brand>
                    </LinkContainer>
                    <ProfileDropdown />
                </Navbar>
            </Container>
        </header>
    );
}

export default Header;
