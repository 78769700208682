import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';

export type User = {
    ID: string
    user_email: string
}

interface Payload extends User {
    iss: string
    exp: string
}

export function user(): User|null {
    const jwt = Cookies.get('jwt');
    if (!jwt) {
        return null;
    }

    const payload = jwt_decode<Payload>(jwt);
    return payload as User;
}