import {useContext} from "react";
import {Link, useParams} from "react-router-dom";
import prettyBytes from 'pretty-bytes';

import {
    findBackup,
    findServer,
    findSite,
    siteDisplayName,
    statusClass,
    localDateTime, serverDisplayName
} from "../utils";
import {ServersContext} from "../App";
import BackupNotFound from "../components/backups/BackupNotFound";
import {BackupActions} from "../components/backups/BackupActions";

const BackupPage = () => {
    let {serverId, siteId, backupId} = useParams();
    const servers = useContext(ServersContext);
    const server = findServer(serverId, servers);
    const site = findSite(siteId, server);
    const backup = findBackup(backupId, site);

    if (!server || !site || !backup) {
        return (<BackupNotFound id={siteId}/>);
    }

    return (
        <>
            <div className="pb-4">
                <h1 className="mb-2 h2">Backup Details</h1>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={'/'}>Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={'/servers/' + server.id}>Server</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={`/servers/${server.id}/sites/${site.id}`}>Site</Link>
                    </li>
                    <li className="breadcrumb-item active">Backup</li>
                </ol>
            </div>

            <div className="card bg-white mb-4 shadow-sm">
                <div className="card-header d-flex align-items-sm-center flex-column flex-sm-row px-4 py-3">
                    <div className="col fs-5 fw-semibold pe-3 mb-3 mb-sm-0">
                        Backup of {siteDisplayName(site)} at {localDateTime(backup.started_at)}
                    </div>
                    <BackupActions backup={backup} site={site} server={server} />
                </div>
                <div className="card-body p-4">
                    <dl className="row">
                        <dt className="col-sm-3 text-nowrap pe-2">Backup ID</dt>
                        <dd className="col-sm-9">{backup.id}</dd>
                        <dt className="col-sm-3">Status</dt>
                        <dd className={"col-sm-9 text-uppercase " + statusClass(backup.status)}>{backup.status}</dd>
                        <dt className="col-sm-3">Environment Type</dt>
                        <dd className="col-sm-9">{site.environment_type}</dd>
                        <dt className="col-sm-3">Site</dt>
                        <dd className="col-sm-9">
                            <Link to={`/servers/${server.id}/sites/${site.id}`}>{siteDisplayName(site)}</Link>
                        </dd>
                        <dt className="col-sm-3">Server</dt>
                        <dd className="col-sm-9">
                            <Link to={`/servers/${server.id}`}>{serverDisplayName(server)}</Link>
                        </dd>
                        {(backup.size > 0) && (
                            <>
                                <dt className="col-sm-3">Size</dt>
                                <dd className="col-sm-9">{prettyBytes(backup.size)}</dd>
                            </>
                        )}
                    </dl>
                </div>
            </div>
        </>
    );
}

export default BackupPage;
