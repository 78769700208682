import {Server} from "../../types";
import Dropdown from "react-bootstrap/Dropdown";
import {ChangeEvent, useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import {ApiError, request} from "../../services/api";
import {AppContext} from "../../App";

const ServerRemoveUsersAction = ({server}: { server: Server }) => {
    const app = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const [ids, setIds] = useState<string[]>([]);

    function removeUser(id: string) {
        const requestOptions = {
            method: 'DELETE'
        };
        return request(`/servers/${server.id}/auth/${id}`, requestOptions)
            .catch((e) => {
                app.setError(e as ApiError);
            });
    }

    function removeUsers() {
        const promises = [];
        for (const id of ids) {
            promises.push(removeUser(id));
        }
        Promise.all(promises)
            .finally(() => {
                setIds([]);
                setShowModal(false);
            })
    }

    function onChange(event: ChangeEvent<HTMLSelectElement>) {
        const selected = Array.from(event.target.selectedOptions).map((item) => item.value);
        setIds(selected);
    }

    function disabled() {
        return (server.auths.length === 0);
    }

    return (
        <>
            <Dropdown.Item as="button" disabled={disabled()} onClick={() => setShowModal(true)}>Remove User(s)</Dropdown.Item>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove User Authorization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Select aria-label="Select users to remove" multiple value={ids} onChange={onChange}>
                            {server.auths.map((auth) => (
                                <option key={auth.id} value={auth.id}>{auth.email}</option>
                            ))}
                        </Form.Select>
                    </Form>
                    <small>Users that you select here will no longer be able to access this server.</small>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={removeUsers}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ServerRemoveUsersAction;