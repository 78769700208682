import Form from "react-bootstrap/Form";
import {ChangeEvent, useContext} from "react";
import {ServersContext} from "../../App";
import {serverDisplayName} from "../../utils";
import {Server} from "../../types";

export type Props = {
    server: Server
    onChange: (server: Server) => void
}

const ServerSelect = ({server, onChange}: Props) => {
    const servers = useContext(ServersContext);

    function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
        const serverId = event.target.value;
        for (const s of servers) {
            if (s.id === serverId) {
                onChange(s);
            }
        }
    }

    return (
        <Form.Select aria-label="Server select" value={server.id} onChange={onSelectChange}>
            {servers.map((s) => (
                <option key={s.id} value={s.id}>{serverDisplayName(s)}</option>
            ))}
        </Form.Select>
    );
}

export default ServerSelect;
