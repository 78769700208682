import {Server} from "../../types";
import Dropdown from "react-bootstrap/Dropdown";
import {ChangeEvent, useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import {ApiError, post} from "../../services/api";
import {AppContext} from "../../App";

const ServerAddUserAction = ({server}: { server: Server }) => {
    const app = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [notify, setNotify] = useState(true);

    function addUser() {
        post(`/servers/${server.id}/auth`, {
            email: email,
            notify: notify,
        })
            .catch((e) => {
                app.setError(e as ApiError);
            })
            .finally(() => {
                setEmail('');
                setShowModal(false);
            })
    }

    function onChangeNotify(event: ChangeEvent<HTMLInputElement>) {
        setNotify(event.target.checked);
    }

    return (
        <>
            <Dropdown.Item as="button" onClick={() => setShowModal(true)}>Add User</Dropdown.Item>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Authorize User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Email</Form.Label>
                            <Form.Control autoFocus type="text" placeholder="Enter email used on mindspun.com" value={email}
                                          onChange={(event) => setEmail(event.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Check checked={notify} onChange={onChangeNotify} type="checkbox" id="notify"
                                        label="Send a notification email to the user?" />
                        </Form.Group>
                    </Form>
                    <small>The added user will have full control over this server, including all sites and backups.</small>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={addUser}>
                        Authorize
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ServerAddUserAction;