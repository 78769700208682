import {useNavigate} from 'react-router-dom';
import {Server} from "../../types";
import {localDateTime, serverDisplayName} from "../../utils";
import ServerIcon from "./ServerIcon";

const ServerLink = ({server}: { server: Server }) => {
    const navigate = useNavigate();

    function onClick() {
        navigate(`/servers/${server.id}`)
    }

    return (        
        <div className="d-flex shadow-sm bg-white p-3 server-link mb-3" role="button" onClick={onClick}>
            <ServerIcon server={server} />
            <div className="col ps-3 row">
                <div className="col-sm-6">
                    <h5 className="card-title">{serverDisplayName(server)}</h5>
                    {(server.provisioned_at !== null) &&
                        <p className="mb-1 text-muted">{localDateTime(server.provisioned_at)}</p>
                    }
                    <p className="text-primary m-0">{server.ipv4}</p>
                </div>
                <div className="col-sm-6 d-flex pt-2 pt-sm-0 flex-wrap small align-items-start align-items-sm-end flex-column">
                    <p className="mb-1 me-2 border border-primary px-3 rounded bg-primary bg-opacity-10 text-primary">{server.info.cpus} VCPUs</p>
                    <p className="mb-1 me-2 border border-primary px-3 rounded bg-primary bg-opacity-10 text-primary">{server.info.ram_gb}GB RAM</p>
                    <p className="mb-1 me-2 border border-primary px-3 rounded bg-primary bg-opacity-10 text-primary">{server.info.disk_gb}GB disk space</p>
                </div>
                
            </div>
        </div>
    )
};

export default ServerLink