import {Backup, Server, Site} from "./types";

export function serverDisplayName(server: Server) {
    return server.title ? server.title : server.name
}

export function siteDisplayName(site: Site) {
    if (site.title) {
        return site.title;
    }
    return site.fqdn ? site.fqdn : site.hostname
}

export function siteHref(site: Site) {
    return site.fqdn ? site.fqdn : site.hostname
}

export function findServer(serverId: string|undefined, servers: Server[]): Server|null {
    if (serverId) {
        for (const server of servers) {
            if (server.id === serverId) {
                return server;
            }
        }
    }
    return null;
}

export function findSite(siteId: string|undefined, server: Server|null): Site|null {
    if (siteId && server) {
        for (const site of server.sites) {
            if (site.id === siteId) {
                return site;
            }
        }
    }
    return null;
}

export function findBackup(backupId: string|undefined, site: Site|null): Backup|null {
    if (backupId && site) {
        for (const backup of site.backups) {
            if (backup.id === backupId) {
                return backup;
            }
        }
    }
    return null;
}

export function backupDisplayName(backup: Backup) {
    const date = new Date(backup.started_at);
    return date.toLocaleString();
}

export function statusClass(status: string): string {
    switch ( status ) {
        case 'ok':
            return 'text-primary';
        case 'failed':
            return 'text-danger';
    }
    return 'text-secondary';
}

export function pending(status: string): boolean {
    return ( status.endsWith("ing"));
}

export function localDateTime(datetime: string): string {
    if (!datetime) {
        return '';
    }
    const date = new Date(datetime);
    return date.toLocaleString();
}

export function splitLines(s: string, trim: boolean = true): string[] {
    const lines = s.split("\n");
    if (trim) {
        const result = [];
        for (let line of lines) {
            line = line.trim();
            if (line) {
                result.push(line);
            }
        }
        return result;
    }
    return lines;
}
