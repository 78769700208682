import {Site} from "../../types";
import {useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import SiteSshKeysModal from "./SiteSshKeysModal";

const SiteSshKeysAction = ({site}: { site: Site }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Dropdown.Item as="button" onClick={() => setShowModal(true)}>SSH Keys</Dropdown.Item>
            <SiteSshKeysModal show={showModal} site={site} onClose={() => setShowModal(false)} />
        </>
    );
}

export default SiteSshKeysAction;