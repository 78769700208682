import Dropdown from "react-bootstrap/Dropdown";

import {LOGOUT_URL} from "../../services/api";

const LogoutItem = () => {
    const url = new URL(LOGOUT_URL);
    url.searchParams.set('redirect_to', window.location.href);
    return (
        <Dropdown.Item href={url.href}>Log out</Dropdown.Item>
    );
};

export default LogoutItem;