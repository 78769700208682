import SiteLink from "./SiteLink";
import {SiteAdd} from "../sites/SiteAdd";
import {Server, Site} from "../../types";
import {ApiError, post} from "../../services/api";
import {useContext} from "react";
import {AppContext} from "../../App";
import {siteDisplayName} from "../../utils";

type Props = {
    server: Server,
    environmentType: string,
    sites: Site[]
}

type SiteCreateRequest = {
    server_id: string,
    environment_type: string,
    site_id: string | null,
    title: string | null,
}

export const ServerSiteContainer = ({server, environmentType, sites}: Props) => {
    const app = useContext(AppContext);

    function siteCreate(environmentType: string, site: Site|null) {
        app.setPending(true);

        const data: SiteCreateRequest = {
            server_id: server.id,
            environment_type: environmentType,
            site_id: null,
            title: null,
        }

        if (site) {
            data.site_id = site.id;
            data.title = 'Copy of ' + siteDisplayName(site);
        }

        post('/sites', data)
            .then(() => {
                app.setMessage('Site successfully created.');
            })
            .catch((e) => {
                app.setError(e as ApiError);
            });
    }

    function onDrop(site: Site, environmentType: string) {
        siteCreate(environmentType, site);
    }

    return (
        <div className="card bg-white shadow-sm mb-4" id={environmentType}>
            <div className="card-header d-flex align-items-center">
                <div className="fw-bold text-uppercase op">{environmentType}</div>
                <span className="badge bg-primary rounded-pill ms-auto">{sites.length}</span>
            </div>

            <div className="card-body p-4">
                <div className="row">
                    {
                        sites.map((site) => (
                            <SiteLink key={site.id} site={site} server={server} onDrop={onDrop}/>
                        ))
                    }
                    <SiteAdd environmentType={environmentType} onClick={() => siteCreate(environmentType, null)} />
                </div>
            </div>
        </div>
    )
}