import {Server} from "../../types";
import {localDateTime, serverDisplayName} from "../../utils";

const ServerListItem = ({server}: { server: Server }) => {
    return (
        <div className="d-flex shadow-sm bg-white p-3 server-link mb-3">
            <div className="col ps-3 row">
                <div className="col-sm-6">
                    <h5 className="mb-1 card-title">{serverDisplayName(server)}</h5>
                    {(server.provisioned_at !== null) &&
                        <p className="mb-1 text-muted">{localDateTime(server.provisioned_at)}</p>
                    }
                    <p className="text-muted mb-1">{server.owner ? server.owner.email : server.owner_id}</p>
                    <p className="text-muted mb-1">{server.hostname}</p>
                    <p className="text-muted mb-1">{server.sites.length} site(s)</p>
                </div>
                <div className="col-sm-6 d-flex pt-2 pt-sm-0 flex-wrap small align-items-start align-items-sm-end flex-column">
                    <p className="mb-1 me-2 border border-primary px-3 rounded bg-primary bg-opacity-10 text-primary">{server.info.cpus} VCPUs</p>
                    <p className="mb-1 me-2 border border-primary px-3 rounded bg-primary bg-opacity-10 text-primary">{server.info.ram_gb}GB RAM</p>
                    <p className="mb-1 me-2 border border-primary px-3 rounded bg-primary bg-opacity-10 text-primary">{server.info.disk_gb}GB disk space</p>
                    <p className="mb-1 me-2 text-primary m-0">{server.ipv4}</p>
                </div>
                
            </div>
        </div>
    )
};

export default ServerListItem