import {ReactSVG} from "react-svg";
import {useDrop} from "react-dnd";

type Props = {
    environmentType: string,
    onClick: () => void
}

export const SiteAdd = ({environmentType, onClick}: Props) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ['production', 'staging', 'development'],
        drop: () => ({ name: environmentType }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    return (
        <div className={'col-sm-4 text-center py-3 '+ ((canDrop && isOver) ? 'bg-success bg-opacity-10' : '')} role="button" onClick={() => onClick()} ref={drop}>
            <div className="position-relative d-inline-block my-3">
                <ReactSVG src="/plus.svg" className="text-secondary server-add-icon" />
            </div>
            <p className="mb-1">Add Site</p>
        </div>
    )
}