import {forwardRef, ReactNode, MouseEvent} from "react";
// import { Link } from 'react-router-dom';

import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

import Avatar from "./Avatar";
import LogoutItem from "./LogoutItem";

interface Props {
    children?: ReactNode;
    type: "submit" | "button";
    onClick: (event: MouseEvent) => void;
}
export type Ref = HTMLButtonElement;

const CustomToggle = forwardRef<Ref, Props>(({ children, onClick }, ref) => (
    <Button variant={'link'}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </Button>
));

const ProfileDropdown = () => {
    // <Dropdown.Item as={Link} to={'/settings'}>Settings</Dropdown.Item>
    return (
        <Dropdown className="ms-auto">
            <Dropdown.Toggle as={CustomToggle} id="profile">
                <Avatar />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <LogoutItem />
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ProfileDropdown