import {useContext, useState} from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';

import {Backup, Site, Server} from "../../types";
import {ApiError, post} from "../../services/api";
import {AppContext} from "../../App";
import Form from "react-bootstrap/Form";
import ServerSelect from "../common/ServerSelect";
import EnvironmentTypeSelect from "../common/EnvironmentTypeSelect";
import {useNavigate} from "react-router-dom";
import {siteDisplayName} from "../../utils";

export type Props = {
    backup: Backup,
    site: Site,
    server: Server
}

const BackupCloneToAction = ({backup, site, server}: Props) => {
    const [showModal, setShowModal] = useState(false);
    const [environmentType, setEnvironmentType] = useState('development');
    const [targetServer, setTargetServer] = useState(server);
    const app = useContext(AppContext);
    const navigate = useNavigate();

    function restoreTo() {

        const data = {
            server_id: targetServer.id,
            environment_type: environmentType,
            backup_id: backup.id,
            title: `Copy of ${siteDisplayName(site)}`
        }

        post('/sites', data)
            .then(() => {
                app.setMessage('Site successfully created.');
            })
            .catch((e) => {
                app.setError(e as ApiError);
            });
        setShowModal(false);
        navigate(`/servers/${targetServer.id}#${environmentType}`);
    }

    return (
        <>
            <Dropdown.Item as="button" onClick={() => setShowModal(true) }>
                Clone To ...
            </Dropdown.Item>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Clone To</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formServer">
                            <Form.Label>Server</Form.Label>
                            <ServerSelect server={targetServer} onChange={setTargetServer}/>
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Environment Type</Form.Label>
                            <EnvironmentTypeSelect value={environmentType} onChange={setEnvironmentType} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={restoreTo}>
                        Clone
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BackupCloneToAction;