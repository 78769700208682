import {Link, useParams} from "react-router-dom";
import {findServer, findSite, localDateTime, serverDisplayName, siteDisplayName, siteHref, statusClass} from "../utils";
import {useContext} from "react";
import {ServersContext} from "../App";
import SiteNotFound from "../components/sites/SiteNotFound";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {Backup} from "../types";
import NoBackups from "../components/sites/NoBackups";
import BackupLink from "../components/backups/BackupLink";
import {SiteActions} from "../components/sites/SiteActions";

const SitePage = () => {
    let {serverId, siteId} = useParams();
    const servers = useContext(ServersContext);
    const server = findServer(serverId, servers);
    const site = findSite(siteId, server);

    if (!server || !site) {
        return (<SiteNotFound id={siteId}/>);
    }

    return (
        <>
            <div className="pb-4">
                <h1 className="mb-2 h2">Site Details</h1>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={'/'}>Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={'/servers/' + server.id}>Server</Link>
                    </li>
                    <li className="breadcrumb-item active">Site</li>
                </ol>
            </div>
            <div className="card bg-white mb-4 shadow-sm">
                <div className="card-header d-flex align-items-sm-center flex-column flex-sm-row px-4 py-3">
                    <div className="col fs-5 fw-semibold pe-3 mb-3 mb-sm-0">
                        {siteDisplayName(site)}
                        <a href={`https://${siteHref(site)}`} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="ms-2 me-auto" icon={faArrowUpRightFromSquare}/>
                        </a>
                    </div>
                    <SiteActions site={site} server={server} />
                </div>
                <div className="card-body p-4">
                    <dl className="row">
                        <dt className="col-sm-3">Site ID</dt>
                        <dd className="col-sm-9">{site.id}</dd>
                        <dt className="col-sm-3">Hostname</dt>
                        <dd className="col-sm-9">{site.hostname}</dd>
                        <dt className="col-sm-3">Status</dt>
                        <dd className={"col-sm-9 text-uppercase " + statusClass(site.status)}>{site.status}</dd>
                        <dt className="col-sm-3">Environment Type</dt>
                        <dd className="col-sm-9">{site.environment_type}</dd>
                        <dt className="col-sm-3">Server</dt>
                        <dd className="col-sm-9">
                            <Link to={'/servers/' + server.id}>{serverDisplayName(server)}</Link>
                        </dd>
                        <dt className="col-sm-3">Created</dt>
                        <dd className="col-sm-9">{localDateTime(site.created_at)}</dd>
                        {
                            !!site.notes && (
                                <>
                                    <dt className="col-sm-3">Notes</dt>
                                    <dd className="col-sm-9">
                                        {
                                            site.notes.split("\n").map((line) => (
                                                    <span>{line}<br/></span>
                                                )
                                            )
                                        }
                                    </dd>
                                </>
                            )
                        }
                    </dl>
                </div>
            </div>
            <div className="card bg-white mb-4 shadow-sm" id="backups">
                <div className="card-header fs-5 p-4 fw-semibold">
                    Backups
                </div>
                <div className="card-body p-4">
                    <div className="row">
                        {site.backups.length === 0 && <NoBackups />}
                        {site.backups.length > 0 && site.backups.map((backup: Backup) => (
                            <BackupLink backup={backup} site={site} server={server} key={backup.id} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SitePage;
