import Dropdown from "react-bootstrap/Dropdown";
import {Site} from "../../types";
import {ApiError, post} from "../../services/api";
import humanizeDuration from "humanize-duration";
import {useContext} from "react";
import {AppContext} from "../../App";

type Props = {
    site: Site
}

const SiteBackupAction = ({site}: Props) => {
    const app = useContext(AppContext);
    const disabled = (site.status !== 'ok');

    function backup() {
        app.setPending(true);
        const start = Date.now();
        post(`/backups`, {site_id: site.id})
            .then(() => {
                app.setMessage(`Backup successfully created in ${humanizeDuration(Date.now() - start)}.`);
            })
            .catch((e) => {
                app.setError(e as ApiError);
                app.setPending(false);
            });
    }


    return (
        <Dropdown.Item as="button" disabled={disabled} onClick={backup}>Backup</Dropdown.Item>
    );
}

export default SiteBackupAction;