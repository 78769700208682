import {Site} from "../../types";
import Dropdown from "react-bootstrap/Dropdown";
import {useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import {ApiError, patch} from "../../services/api";
import {AppContext} from "../../App";

const SiteEditAction = ({site}: { site: Site }) => {
    const app = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState(site.title ? site.title : '');
    const [notes, setNotes] = useState(site.notes ? site.notes : '');

    function edit() {
        patch(`/sites/${site.id}`, {
            title: title,
            notes: notes,
        })
            .catch((e) => {
                app.setError(e as ApiError);
            })
            .finally(() => {
                setShowModal(false);
            });
    }

    return (
        <>
            <Dropdown.Item as="button" onClick={() => setShowModal(true)}>Edit</Dropdown.Item>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Site</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Enter title" value={title}
                                          onChange={(event) => setTitle(event.target.value)}/>
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} name="notes" value={notes}
                                          onChange={(event) => setNotes(event.target.value)}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={edit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SiteEditAction;