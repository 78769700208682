import {useNavigate} from 'react-router-dom';

import {Site, Server, Backup} from "../../types";
import {backupDisplayName} from "../../utils";
import prettyBytes from "pretty-bytes";
import BackupIcon from "./BackupIcon";

const BackupLink = ({backup, site, server}: {backup: Backup, site: Site, server: Server}) => {
    const navigate = useNavigate();

    function onClick() {
        navigate(`/servers/${server.id}/sites/${site.id}/backups/${backup.id}`)
    }
    return (
        <div className="col-sm-3 text-center py-3" role="button" onClick={onClick}>
            <BackupIcon backup={ backup }/>
            <p className="mb-1">{backupDisplayName(backup)}</p>
            <p className="text-secondary small">
                {(backup.size > 0) ? prettyBytes(backup.size) : ''}
            </p>
        </div>
    )
};

export default BackupLink;
