import {useContext} from "react";

import {ServersContext} from "../App";

import ServerLink from "../components/servers/ServerLink";
import NoServers from "../components/servers/NoServers";

function HomePage() {
    const servers = useContext(ServersContext);
    return (
        <>
            { ( servers.length === 0 ) && <NoServers /> }
            {
                servers.map((server) => (
                    <ServerLink key={server.id} server={server} />
                ))
            }
        </>
    )
}

export default HomePage;
