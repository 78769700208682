import {useContext, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';

import {Site} from "../../types";
import {AppContext} from "../../App";
import {ApiError, get, post} from "../../services/api";
import {splitLines} from "../../utils";
import {Spinner} from "react-bootstrap";

type Props = {
    show: boolean
    site: Site
    onClose: () => void;
}

const SiteSshKeysModal = ({site, onClose, show}: Props) => {
    const app = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState('');

    useEffect(() => {
        get<string[]>(`/sites/${site.id}/ssh-keys`)
            .then((data) => {
                setValue(data.join("\n"));
            })
            .catch((e) => {
                app.setError(e as ApiError);
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue]);

    function save() {
        post(`/sites/${site.id}/ssh-keys`, {
            keys: splitLines(value)
        })
            .catch((e) => {
                app.setError(e as ApiError);
            })
        onClose();
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Site SSH Keys</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && (
                    <div className="d-flex align-items-center justify-content-center my-4">
                        <Spinner size="sm" animation="border"></Spinner>
                    </div>
                )}
                {!loading && (
                    <Form>
                        <Form.Label>
                            Copy and paste your <span className="text-warning">public</span> key(s) here.
                        </Form.Label>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows={3} name="notes" value={value}
                                          onChange={(event) => setValue(event.target.value)}/>
                        </Form.Group>
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary" disabled={loading} onClick={save}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SiteSshKeysModal;