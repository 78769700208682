import {Navigate, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home";
import SettingsPage from "./pages/Settings";
import ServerPage from "./pages/Server";
import SitePage from "./pages/Site";
import BackupPage from "./pages/Backup";

const MainRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout/>} errorElement={<ErrorPage/>}>
                <Route index element={<HomePage/>}/>
                <Route path="settings" element={<SettingsPage/>}/>
                <Route path="/servers/:serverId" element={<ServerPage/>}/>
                <Route path="/servers/:serverId/sites/:siteId" element={<SitePage/>}/>
                <Route path="/servers/:serverId/sites/:siteId/backups/:backupId" element={<BackupPage/>}/>
            </Route>
        </Routes>
    );
}

export default MainRouter;