import Dropdown from 'react-bootstrap/Dropdown';

import {Site, Server} from "../../types";

import SiteEditAction from "./SiteEditAction";
import SiteSslAction from "./SiteSslAction";
import SiteBackupAction from "./SiteBackupAction";
import SiteDeleteAction from "./SiteDeleteAction";
import SiteCloneAction from "./SiteCloneAction";
import SiteSshKeysAction from "./SiteSshKeysAction";


export const SiteActions = ({site, server}: { site: Site, server: Server }) => {
    return (
        <>
            <Dropdown className="float-md-end">
                <Dropdown.Toggle id="dropdown-actions">
                    Actions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <SiteCloneAction environmentType={"production"} site={site} server={server} />
                    <SiteCloneAction environmentType={"staging"} site={site} server={server} />
                    <SiteCloneAction environmentType={"development"} site={site} server={server} />
                    <li>
                        <hr className="dropdown-divider"/>
                    </li>
                    <SiteSshKeysAction site={site} />
                    <SiteSslAction site={site} />
                    <SiteEditAction site={site} />
                    <SiteBackupAction site={site} />
                    <li>
                        <hr className="dropdown-divider"/>
                    </li>
                    <SiteDeleteAction server={server} site={site} />
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}
