export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://api.mindspun.com/builder';
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL ? process.env.REACT_APP_LOGIN_URL : 'https://www.mindspun.com/login/';
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL ? process.env.REACT_APP_LOGOUT_URL : 'https://www.mindspun.com/wp-login.php?action=logout';
export const WEBSOCKET_URL = 'ws' + API_URL.substring(4) + '/ws';

export class ApiError extends Error {
    res: Response;

    constructor(res: Response, message: string) {
        super(message)
        this.res = res;
    }

    static async fromResponse(res: Response): Promise<ApiError> {
        let message = res.statusText;
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.startsWith("application/json")) {
            const json = await res.json();
            message = json.detail ? json.detail : message;
        }
        return new ApiError(res, message);
    }
}

function redirectLogin() {
    window.location.href = LOGIN_URL + '?redirect_to=' + encodeURI(window.location.href);
}

export interface ApiRequestInit extends Omit<RequestInit, 'headers'> {
    headers?: Record<string, string>;
}

export async function request<T>(input: string, init: ApiRequestInit | undefined ): Promise<T> {
    if (input.startsWith('/')) {
        input = API_URL + input;
    }

    init = init || {};
    init.headers = init.headers || {};
    init.credentials = 'include';

    const res = await fetch(input, init);

    if (res.status === 401) {
        redirectLogin();
        throw await ApiError.fromResponse(res);
    }

    if (res.status === 204) {
        return {} as T;
    }

    if (!res.ok) {
        throw await ApiError.fromResponse(res);
    }

    const json = await res.json();

    const {status, errors} = json;
    if (status === undefined || status) {
        return json;
    }
    const error = errors.length > 0 ? errors[0] : res.statusText
    throw new ApiError(res, error);
}

export function patch<T>(url: string, data: any) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return request<T>(url, requestOptions);
}

export function post<T>(url: string, data: any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return request<T>(url, requestOptions);
}

export function get<T>(url: string) {
    const requestOptions = {
        method: 'GET'
    };
    return request<T>(url, requestOptions);
}
