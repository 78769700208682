import Dropdown from "react-bootstrap/Dropdown";
import {Site, Server} from "../../types";
import {ApiError, request} from "../../services/api";
import {useContext, useState} from "react";
import {AppContext} from "../../App";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";

type Props = {
    server: Server
    site: Site
}

const SiteDeleteAction = ({site, server}: Props) => {
    const app = useContext(AppContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigate = useNavigate();

    async function onDelete() {
        site.status = 'deleting';
        try {
            const requestOptions = {
                method: 'DELETE'
            };
            request(`/sites/${site.id}`, requestOptions).then(
                () => {
                    app.setMessage('Site successfully deleted.');
                }
            )
            navigate(`/servers/${server.id}#${site.environment_type}`)
        } catch (e) {
            app.setError(e as ApiError);
        }
        setShowDeleteModal(false);
    }

    function backupText(site: Site) {
        if (site.backups.length === 1) {
            return " along with it's 1 backup";
        } else if (site.backups.length > 1) {
            return ` along with its ${site.backups.length} backups`;
        }
        return '';
    }

    return (
        <>
            <Dropdown.Item as="button" onClick={() => setShowDeleteModal(true)}>
                <span className="text-danger">Delete</span>
            </Dropdown.Item>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete.</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to permanently delete this <span
                    className="text-uppercase fw-bold">{site.environment_type}</span> site
                    {backupText(site)}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={onDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SiteDeleteAction;