function NoServers() {
    return (
        <div className="bg-white p-5 text-center">
            <h2>No Servers Found</h2>
            <p className="lead">Please purchase a new server or have an admin grant you access to an existing one.</p>
        </div>
    )
}

export default NoServers;
